import React, { useState, useEffect } from 'react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './UserUpdateOverlay.scss';
import API_BASE_URL from '../../Config/config';

const UserUpdateOverlay = ({ user, onClose, onUpdate }) => {
  const [formData, setFormData] = useState({
    fullname: '',
    email: '',
    username: '',
    accountNumber: '',
    accountBalance: 0,
    accountType: '',
  });

  useEffect(() => {
    if (user) {
      setFormData({
        fullname: user.fullname || '',
        email: user.email || '',
        username: user.username || '',
        accountNumber: user.accountNumber || '',
        accountBalance: user.accountBalance || 0,
        accountType: user.accountType || '',
      });
    }
  }, [user]);

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!user?._id) {
      toast.error('User ID is missing. Cannot update user.');
      return;
    }

    try {
      const response = await fetch(`${API_BASE_URL}/admin/updateuser/${user._id}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to update user');
      }

      const updatedUser = await response.json();
      onUpdate(updatedUser);
      toast.success('User updated successfully!');
      onClose();
    } catch (error) {
      toast.error(`Error updating user: ${error.message}`);
    }
  };

  const accountTypes = [
    'savings account',
    'checking account',
    'money market',
    'certificate of deposit',
    'individual retirement account',
    'joint account',
    'fixed deposit account',
  ];

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>Update User</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Number:
            <input
              type="text"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Balance:
            <input
              type="number"
              name="accountBalance"
              value={formData.accountBalance}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Type:
            <select
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
              required
            >
              <option value="">-- Select Account Type --</option>
              {accountTypes.map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </label>
          <button type="submit">Submit</button>
          <button type="button" onClick={onClose}>Cancel</button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default UserUpdateOverlay;
