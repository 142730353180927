// CreditTransaction.js
import React, { useState, useEffect } from "react";
import axios from "axios";
import "./Transaction.scss";
import { getToken } from "../../utils/utils";
import API_BASE_URL from "../../Config/config";

const CreditTransaction = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = getToken();
        const response = await axios.get(`${API_BASE_URL}/user/transactions/credit`, {
          headers: { Authorization: `Bearer ${token}` }
        });

        // Sort transactions by createdAt in descending order
        const sortedTransactions = response.data.transactions.sort(
          (a, b) => new Date(b.createdAt) - new Date(a.createdAt)
        );

        setTransactions(sortedTransactions);
      } catch (error) {
        console.error("Error fetching credit transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  const totalPages = Math.ceil(transactions.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = transactions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Sender</th>
            <th>Amount</th>
            <th>Bank</th>
            <th>Description</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.sourceName}</td>
              <td style={{ color: "darkgreen" }}>
                $
                {new Intl.NumberFormat("en-Us", {
                  style: "currency",
                  currency: "USD",
                })
                .format(transaction.transferAmount)
                .slice(1)}
              </td>
              <td>{transaction.sourceBankName}</td>
              <td>{transaction.paymentDescription}</td>
              <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default CreditTransaction;
