import React, { useState, useEffect } from 'react';
import './Carousel.scss';
import image1 from '../../assets/images/ManPC.jpg';
import image2 from '../../assets/images/coinjar.jpg';
import image3 from '../../assets/images/chatfx.jpg';

const Carousel = () => {
  const [currentIndex, setCurrentIndex] = useState(0);
  const slides = [
    { id: 1, content: <img src={image1} alt="Slide 1" />, caption: <><h1>Bank Anytime, Anywhere</h1><br/><p>Access your accounts and manage your finances with our convenient online and mobile banking services.</p></>},
    { id: 2, content: <img src={image2} alt="Slide 2" />, caption: <><h1>Tailored Financial Solutions</h1><br/><p>We offer personalized banking and financial services to meet your unique needs and goals.</p></> },
    { id: 3, content: <img src={image3} alt="Slide 3" />, caption: <><h1>Experience Secure Banking</h1><br/><p>Your peace of mind is our priority. Enjoy secure and reliable banking services with us.</p></> },
  ];

  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="carousel">
      {slides.map((slide, index) => (
        <div
          key={slide.id}
          className={`carousel-slide ${index === currentIndex ? 'active' : ''}`}
        >
          {slide.content}
          <div className="caption">{slide.caption}</div>
        </div>
      ))}
      <div className="carousel-indicators">
        {slides.map((_, index) => (
          <button
            key={index}
            className={`indicator ${index === currentIndex ? 'active' : ''}`}
            onClick={() => setCurrentIndex(index)}
          />
        ))}
      </div>
    </div>
  );
};

export default Carousel;
