import React, { useState } from 'react';
import axios from 'axios';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AddBeneficiary.scss';
import Footer from '../../components/footer/Footer';
import HeaderNoButton from '../../components/header/HeaderNoButton';
import CustomSidebar from '../../components/sidebar/Sidebar';
import Logout from '../../components/Button/Logout';
import API_BASE_URL from '../../Config/config';

const BeneficiaryForm = () => {
  const [formData, setFormData] = useState({
    fullName: '',
    email: '',
    relationship: '',
    dateOfBirth: '',
    address: '',
    ssn: '',
    percentageAllocation: ''
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const token = localStorage.getItem('token'); // assuming the token is stored in localStorage
      await axios.post(`${API_BASE_URL}/user/add-beneficiary`, formData, {
        headers: {
          Authorization: `Bearer ${token}`
        }
      });
      toast.success('Beneficiary added successfully');
      setFormData({
        fullName: '',
        email: '',
        relationship: '',
        dateOfBirth: '',
        address: '',
        ssn: '',
        percentageAllocation: ''
      });
    } catch (error) {
      console.error('Error adding beneficiary', error);
      toast.error('Failed to add beneficiary');
    }
  };

  return (
    <React.Fragment>
      <HeaderNoButton />
      <CustomSidebar />
      <div className="form-container">
        <ToastContainer />
        <h2 className="form-title">Add Beneficiary</h2>
        <form className="beneficiary-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="fullName">Full Name</label>
            <input
              type="text"
              id="fullName"
              name="fullName"
              value={formData.fullName}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email">Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="relationship">Relationship</label>
            <select
              id="relationship"
              name="relationship"
              value={formData.relationship}
              onChange={handleChange}
              required
            >
              <option value="">Select Relationship</option>
              <option value="Parent">Parent</option>
              <option value="Spouse">Spouse</option>
              <option value="Child">Child</option>
              <option value="Sibling">Sibling</option>
              <option value="Friend">Friend</option>
              <option value="Other">Other</option>
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="dateOfBirth">Date of Birth</label>
            <input
              type="date"
              id="dateOfBirth"
              name="dateOfBirth"
              value={formData.dateOfBirth}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address">Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="ssn">Social Security Number</label>
            <input
              type="text"
              id="ssn"
              name="ssn"
              value={formData.ssn}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="percentageAllocation">Percentage Allocation</label>
            <input
              type="number"
              id="percentageAllocation"
              name="percentageAllocation"
              value={formData.percentageAllocation}
              onChange={handleChange}
              min="0"
              max="100"
              required
            />
          </div>
          <button type="submit" className="submit-button">Submit</button>
        </form>
      </div>
      <Logout />
      <Footer />
    </React.Fragment>
  );
};

export default BeneficiaryForm;
