import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './PasswordResetForm.scss';
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const PasswordResetForm = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');
  const [newPassword, setNewPassword] = useState('');
  const [message, setMessage] = useState('');

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/users`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching users:', error);
      setMessage('Error fetching users.');
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
  
    if (!selectedUser || !newPassword) {
      setMessage('Please select a user and enter a new password.');
      return;
    }
  
    try {
      const response = await axios.put(`${API_BASE_URL}/admin/${selectedUser}/change-password`, { newPassword });
      setMessage(response.data.message);
    } catch (error) {
      console.error('Error changing password:', error.response ? error.response.data : error.message);
      setMessage(error.response && error.response.data.message ? error.response.data.message : 'Error changing password.');
    }
  };
  

  return (
    <>
    <Header/>
      <div className="password-reset-form">
        <h2>Reset User Password</h2>
        {message && <p className="message">{message}</p>}
        <form onSubmit={handleFormSubmit}>
          <div className="form-group">
            <label htmlFor="username">Select User</label>
            <select id="username" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
              <option value="">-- Select a User --</option>
              {users.map((user) => (
                <option key={user._id} value={user.username}>
                  {user.username}
                </option>
              ))}
            </select>
          </div>
          <div className="form-group">
            <label htmlFor="newPassword">New Password</label>
            <input
              type="password"
              id="newPassword"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
            />
          </div>
          <button type="submit">Reset Password</button>
        </form>
      </div>
      <Footer/>
    </>
  );
};

export default PasswordResetForm;
