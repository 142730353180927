import React, { useEffect, useState } from "react";
// import Card from "react-bootstrap/Card";
import "./Beneficiary.scss";
import CustomSidebar from "../../components/sidebar/Sidebar";
import HeaderNoButton from "../../components/header/HeaderNoButton";
import Footer from "../../components/footer/Footer";
import Logout from "../../components/Button/Logout";
import Card from '../../components/Card/BeneficiaryCard'
import { ToastContainer, toast } from "react-toastify";
import axios from "axios";
import API_BASE_URL from "../../Config/config";

const Beneficiary = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const token = localStorage.getItem('token'); // assuming the token is stored in localStorage
        const response = await axios.get(`${API_BASE_URL}/user/beneficiaries/logged-in-user`, {
          headers: {
            Authorization: `Bearer ${token}`
          }
        });
        setBeneficiaries(response.data.beneficiaries);
      } catch (error) {
        if (error.response && error.response.status === 404) {
          toast.error('No beneficiaries found');
        } else {
          toast.error('Failed to fetch beneficiaries');
        }
      } finally {
        setLoading(false);
      }
    };

    fetchBeneficiaries();
  }, []);

  if (loading) {
    return <p>Loading...</p>;
  }

  return (
    <React.Fragment>
      <HeaderNoButton/>
      <CustomSidebar/>
    <div className="beneficiary-container">
      <ToastContainer />
      <h2>Your Beneficiaries</h2>
      {beneficiaries.length === 0 ? (
        <p>No beneficiaries found</p>
      ) : (
        beneficiaries.map(beneficiary => (
          <Card 
            key={beneficiary._id} 
            fullName={beneficiary.fullName} 
            relationship={beneficiary.relationship} 
            dateOfBirth={new Date(beneficiary.dateOfBirth).toLocaleDateString()} 
            address={beneficiary.address} 
            percentageAllocation={beneficiary.percentageAllocation}
            approval={beneficiary.approval} 
          />
        ))
      )}
    </div>
    <Logout/>
    <Footer/>
    </React.Fragment>
  );
};

export default Beneficiary;