import React from 'react';
import './TransactionTable.scss';

const TransactionTable = ({ transactions = [], onDelete, onDateChange, currentPage }) => {
  // Sort transactions by createdAt date in descending order
  const sortedTransactions = [...transactions].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

  // Calculate the range of transactions to display for the current page
  const startIndex = (currentPage - 1) * 10;
  const endIndex = startIndex + 10;
  const paginatedTransactions = sortedTransactions.slice(startIndex, endIndex);

  return (
    <table className="transaction-table">
      <thead>
        <tr>
          <th>Payment Descriptions</th>
          <th>Type</th>
          <th>Amount</th>
          <th>Created At</th>
          <th>Actions</th>
        </tr>
      </thead>
      <tbody>
        {paginatedTransactions.length > 0 ? (
          paginatedTransactions.map(transaction => (
            <tr key={transaction._id}>
              <td>{transaction.paymentDescription}</td>
              <td>{transaction.type}</td>
              <td>{transaction.transferAmount}</td>
              <td>
                <input
                  type="date"
                  value={transaction.createdAt.split('T')[0]}
                  onChange={(e) => onDateChange(transaction._id, transaction.type, e.target.value)}
                  onBlur={(e) => onDateChange(transaction._id, transaction.type, e.target.value)}
                />
              </td>
              <td>
                <button onClick={() => onDelete(transaction._id, transaction.type)}>Delete</button>
              </td>
            </tr>
          ))
        ) : (
          <tr>
            <td colSpan="5">No transactions available</td>
          </tr>
        )}
      </tbody>
    </table>
  );
};

export default TransactionTable;
