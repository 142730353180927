import React from 'react';
import { Navigate } from 'react-router-dom';
import { isAuthenticated } from '../Auth/auth';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const UserProtectedRoute = ({ children }) => {
  if (!isAuthenticated()) {
    toast.error("Only authorized users only");
    return (
      <>
        <ToastContainer />
        <Navigate to="/login" />
      </>
    );
  }
  
  return children;
};

export default UserProtectedRoute;

