import React from 'react';
import { useNavigate } from 'react-router-dom';
import './AdminDashboard.scss';
import Footer from '../../components/footer/Footer';
import Logout from '../../components/Button/AdminLogoutButton';
import Header from '../../components/header/AdminHeader';

const AdminDashboard = () => {
  const navigate = useNavigate();

  const navigateTo = (path) => {
    navigate(path);
  };

  return (
    <React.Fragment>
    <Header/>
    <div className="dashboard-container">
      <h1 className="dashboard-title">Admin Dashboard</h1>
      <div className="card-grid">
        <div className="dashboard-card" onClick={() => navigateTo('/admin/allusers')}>
          <h3>All Users</h3>
        </div>
        {/* <div className="dashboard-card" onClick={() => navigateTo('/admin/create-user')}>
          <h3>Create Users</h3>
        </div> */}
        <div className="dashboard-card" onClick={() => navigateTo('/admin/all-beneficiaries')}>
          <h3>All Beneficiaries</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/all-customerinfo')}>
          <h3>All Customer Info</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/add-customerInfo')}>
          <h3>Add Customer Info</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/credit-account')}>
          <h3>Credit User Account</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/transactions')}>
          <h3>All Transactions</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/reset-password')}>
          <h3>Reset Password</h3>
        </div>
        <div className="dashboard-card" onClick={() => navigateTo('/admin/fixed-deposits')}>
          <h3>Fixed Deposit Accounts</h3>
        </div>
      </div>      
    </div>
    <Logout/>
    <Footer/>

    </React.Fragment>
  );
};

export default AdminDashboard;
