import React from "react";
import Carousel from "../../components/carousel/Carousel.jsx";
import "./Home.scss";
import Header from "../../components/header/Header.jsx";
import InfoCards from "../../components/Card/InfoCards.js";
import InfoSection from "../../components/section/InfoSection.js";
import AboutSection from "../../components/section/AboutUsSection.js";
import TestimonialSlider from "../../components/section/Testimonial.js";
import Footer from "../../components/footer/Footer.js";
import TawkTo from "../../components/Tawkto/Tawkto.js";

const Home = () => {
  return (
    <React.Fragment>
      <div className="Header">
        <Header />
      </div>      
      <div className="carousel">
        <Carousel />
      </div>
      <InfoCards/>
      <InfoSection/>
      <AboutSection/>
      <TestimonialSlider/>
      <TawkTo/>
      <Footer/>
    </React.Fragment>
  );
};

export default Home;
