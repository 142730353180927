import React, { useState, useEffect } from 'react';
import axios from 'axios';
import TransactionTable from '../../components/table/TransactionTable';
import Pagination from '../../components/pagination/TransactionPagination';
import './Transactions.scss';
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const Transactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);

  useEffect(() => {
    fetchTransactions();
  }, [currentPage]);

  const fetchTransactions = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/transactions`);
      const allTransactions = response.data.transactions || [];
      setTransactions(allTransactions);
      setTotalPages(Math.ceil(allTransactions.length / 10)); // Calculate total pages
    } catch (error) {
      console.error('Error fetching transactions:', error);
      setTransactions([]);
      setTotalPages(1);
    }
  };

  const handleDelete = async (transactionId, type) => {
    try {
      await axios.delete(`${API_BASE_URL}/admin/transactions`, { data: { transactionId, type } });
      fetchTransactions();
    } catch (error) {
      console.error('Error deleting transaction:', error);
    }
  };

  const handleDateChange = async (transactionId, type, newDate) => {
    try {
      await axios.put(`${API_BASE_URL}/admin/transactions/date`, { transactionId, type, newDate });
      fetchTransactions();
    } catch (error) {
      console.error('Error updating transaction date:', error);
    }
  };

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  return (
    <>
    <Header/>
    <div className="app">
      <h1>Transactions</h1>
      <TransactionTable
        transactions={transactions}
        onDelete={handleDelete}
        onDateChange={handleDateChange}
        currentPage={currentPage}
      />
      <Pagination currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
    </div>
    <Footer/>
    </>
    
  );
};

export default Transactions;
