import React from 'react';
import { useNavigate } from 'react-router-dom';
import { logout } from '../Auth/auth';
import { ToastContainer, toast } from 'react-toastify';
import './Button.scss';

const Logout = () => {
  const navigate = useNavigate();

  const handleLogout = () => {
    logout();
    toast.success('Logout successful');
    setTimeout(() => {
      navigate('/login');
    }, 2000); // Delay navigation for 2 seconds
  };

  return (
    <div className="logout-container">
      <button className="logout-button" onClick={handleLogout}>
        Logout
      </button>
      <ToastContainer />
    </div>
  );
};

export default Logout;
