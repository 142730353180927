import React, { useState, useEffect } from "react";
import axios from "axios";
import "./AllTransactionTable.scss";
import { getToken } from "../../utils/utils";
import API_BASE_URL from "../../Config/config";

const AllTransactions = () => {
  const [transactions, setTransactions] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [rowsPerPage] = useState(5);

  useEffect(() => {
    const fetchTransactions = async () => {
      try {
        const token = getToken();

        // Fetch credit transactions
        const creditResponse = await axios.get(`${API_BASE_URL}/user/transactions/credit`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Fetch debit transactions
        const debitResponse = await axios.get(`${API_BASE_URL}/user/transactions/debit`, {
          headers: { Authorization: `Bearer ${token}` },
        });

        // Combine both transactions and sort by date in descending order
        const combinedTransactions = [
          ...creditResponse.data.transactions.map((transaction) => ({
            ...transaction,
            type: "credit",
          })),
          ...debitResponse.data.transactions.map((transaction) => ({
            ...transaction,
            type: "debit",
          })),
        ].sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt));

        setTransactions(combinedTransactions);
      } catch (error) {
        console.error("Error fetching transactions:", error);
      }
    };

    fetchTransactions();
  }, []);

  const totalPages = Math.ceil(transactions.length / rowsPerPage);

  const handlePageChange = (page) => {
    setCurrentPage(page);
  };

  const paginatedData = transactions.slice(
    (currentPage - 1) * rowsPerPage,
    currentPage * rowsPerPage
  );

  return (
    <div>
      <table>
        <thead>
          <tr>
            <th>Party</th>
            <th>Amount</th>
            <th>Bank</th>
            <th>Description</th>
            <th>Date</th>
          </tr>
        </thead>
        <tbody>
          {paginatedData.map((transaction, index) => (
            <tr key={index}>
              <td>{transaction.type === "credit" ? transaction.sourceName : transaction.recipientName}</td>
              <td style={{ color: transaction.type === "credit" ? "green" : "red" }}>
                {transaction.type === "credit" ? "+" : "-"}$
                {new Intl.NumberFormat("en-US", {
                  style: "currency",
                  currency: "USD",
                }).format(transaction.transferAmount).slice(1)}
              </td>
              <td>{transaction.type === "credit" ? transaction.sourceBankName : transaction.bankName}</td>
              <td>{transaction.paymentDescription}</td>
              <td>{new Date(transaction.createdAt).toLocaleDateString()}</td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="pagination">
        {Array.from({ length: totalPages }, (_, index) => (
          <button
            key={index}
            onClick={() => handlePageChange(index + 1)}
            className={currentPage === index + 1 ? "active" : ""}
          >
            {index + 1}
          </button>
        ))}
      </div>
    </div>
  );
};

export default AllTransactions;
