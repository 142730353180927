import React, { useState, useEffect } from 'react';
import './AllUsers.scss';
import UserUpdateOverlay from '../../components/UserUpdateOverlay/UserUpdateOverlay';
import CreateUser from '../../components/CreateUser/CreateUser';
import Header from '../../components/header/AdminHeader';
import API_BASE_URL from '../../Config/config';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const AllUsers = () => {
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState(null);
  const [showCreateUser, setShowCreateUser] = useState(false);

  useEffect(() => {
    fetchUsers();
  }, []);

  const fetchUsers = async () => {
    try {
      const response = await fetch(`${API_BASE_URL}/admin/users`);
      if (response.ok) {
        const data = await response.json();
        setUsers(data);
      } else {
        console.error('Failed to fetch users');
      }
    } catch (error) {
      console.error('Failed to fetch users', error);
    }
  };

  const deleteUser = async (id) => {
    try {
      const response = await fetch(`${API_BASE_URL}/admin/delete-user/${id}`, {
        method: 'DELETE',
      });
      if (response.ok) {
        setUsers(users.filter(user => user._id !== id));
        toast.success('User deleted successfully!');
      } else {
        console.error('Failed to delete user');
        toast.error('Failed to delete user');
      }
    } catch (error) {
      console.error('Failed to delete user', error);
      toast.error('Failed to delete user');
    }
  };

  const toggleSuspendUser = async (user, currentlySuspended) => {
    try {
      const endpoint = currentlySuspended
        ? `${API_BASE_URL}/admin/unsuspend/${user._id}`
        : `${API_BASE_URL}/admin/suspend/${user._id}`;
      const response = await fetch(endpoint, {
        method: 'PUT',
      });
      if (response.ok) {
        const updatedUser = await response.json();
        setUsers(users.map(u => u._id === updatedUser._id ? updatedUser : u));
        const action = currentlySuspended ? 'unsuspended' : 'suspended';
        toast.success(`User successfully ${action}!`);
        setTimeout(() => {
          window.location.reload(); // Refresh the page after a short delay
        }, 2000); // Delay to allow the toast to be visible
      } else {
        console.error('Failed to toggle user suspension');
        toast.error('Failed to toggle user suspension');
      }
    } catch (error) {
      console.error('Failed to toggle user suspension', error);
      toast.error('Failed to toggle user suspension');
    }
  };

  const handleUpdateClick = (user) => {
    setSelectedUser(user);
  };

  const handleUpdate = (updatedUser) => {
    setUsers(users.map(user => user._id === updatedUser._id ? updatedUser : user));
  };

  const handleCreateUser = (newUser) => {
    setUsers([...users, newUser]);
    setShowCreateUser(false);
  };

  return (
    <div className="all-users-container">
      <Header />
      <h1 className="title">All Users</h1>
      <button className="action-button create-user" onClick={() => setShowCreateUser(true)}>Create User</button>
      <table className="user-table">
        <thead>
          <tr>
            <th>Full Name</th>
            <th>Username</th>
            <th>Email</th>
            <th>Account Number</th>
            <th>Account Balance</th>
            <th>Suspension Status</th>
            <th>Actions</th>
          </tr>
        </thead>
        <tbody>
          {users.map(user => (
            <tr key={user._id}>
              <td>{user.fullname}</td>
              <td>{user.username}</td>
              <td>{user.email}</td>
              <td>{user.accountNumber}</td>
              <td>{user.accountBalance}</td>
              <td>{user.suspended ? 'Suspended' : 'Active'}</td>
              <td>
                <button
                  className={`action-button ${user.suspended ? 'unsuspend-button' : 'suspend-button'}`}
                  onClick={() => toggleSuspendUser(user, user.suspended)}
                >
                  {user.suspended ? 'Unsuspend' : 'Suspend'}
                </button>
                <button className="action-button delete-button" onClick={() => deleteUser(user._id)}>Delete</button>
                <button className="action-button update-button" onClick={() => handleUpdateClick(user)}>Update</button>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {selectedUser && (
        <UserUpdateOverlay
          user={selectedUser}
          onClose={() => setSelectedUser(null)}
          onUpdate={handleUpdate}
        />
      )}
      {showCreateUser && (
        <CreateUser
          onClose={() => setShowCreateUser(false)}
          onCreate={handleCreateUser}
        />
      )}
      <ToastContainer />
    </div>
  );
};

export default AllUsers;
