import React, { useState, useEffect } from 'react';
import axios from 'axios';
import './FixedDespositForm.scss'; // Import SCSS file for styling
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const FixedDepositForm = () => {
  const [users, setUsers] = useState([]); // State to store users with fixed deposit accounts
  const [selectedUser, setSelectedUser] = useState(''); // State to store selected user
  const [status, setStatus] = useState(''); // State to store status (locked or unlocked)
  const [date, setDate] = useState(''); // State to store date

  // Fetch users with fixed deposit accounts on component mount
  useEffect(() => {
    fetchFixedDepositAccounts();
  }, []);

  // Function to fetch users with fixed deposit accounts
  const fetchFixedDepositAccounts = async () => {
    try {
      const response = await axios.get(`${API_BASE_URL}/admin/fixed-deposit-accounts`);
      setUsers(response.data);
    } catch (error) {
      console.error('Error fetching fixed deposit accounts:', error);
    }
  };

  // Function to handle form submission
  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!selectedUser || !status || !date) {
      console.error('Please fill in all fields');
      return;
    }

    try {
      await axios.post(`${API_BASE_URL}/admin/fixed-deposit-status`, {
        userId: selectedUser,
        status,
        date,
      });
      console.log('Fixed deposit status updated successfully');
      // Clear form fields after submission
      setSelectedUser('');
      setStatus('');
      setDate('');
    } catch (error) {
      console.error('Error updating fixed deposit status:', error);
    }
  };

  return (
    <>
    <Header/>
        <div className="fixed-deposit-form">
        <h2>Update Fixed Deposit Status</h2>
        <form onSubmit={handleSubmit}>
            <div className="form-group">
            <label htmlFor="user">Select User:</label>
            <select id="user" value={selectedUser} onChange={(e) => setSelectedUser(e.target.value)}>
                <option value="">-- Select User --</option>
                {users.map((user) => (
                <option key={user._id} value={user._id}>
                    {user.username}
                </option>
                ))}
            </select>
            </div>
            <div className="form-group">
            <label htmlFor="status">Status:</label>
            <select id="status" value={status} onChange={(e) => setStatus(e.target.value)}>
                <option value="">-- Select Status --</option>
                <option value="locked">Locked</option>
                <option value="unlocked">Unlocked</option>
            </select>
            </div>
            <div className="form-group">
            <label htmlFor="date">Date:</label>
            <input type="date" id="date" value={date} onChange={(e) => setDate(e.target.value)} />
            </div>
            <button type="submit">Update Status</button>
        </form>
        </div>
        <Footer/>
    </>
    
  );
};

export default FixedDepositForm;
