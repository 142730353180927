import React, { useState, useEffect } from 'react';
import './CreditUserAccount.scss';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const CreditBank = () => {
  const [formData, setFormData] = useState({
    sourceName: '',
    sourceBankAccountNumber: '',
    sourceBankName: '',
    transferAmount: '',
    paymentDescription: '',
    transferType: 'Credit transfer'
  });
  const [users, setUsers] = useState([]);
  const [selectedUser, setSelectedUser] = useState('');

  useEffect(() => {
    const fetchUsers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/admin/users`); // Adjust the endpoint to match your backend
        const usersData = await response.json();
        setUsers(usersData);
      } catch (error) {
        console.error('Error fetching users:', error);
      }
    };

    fetchUsers();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleUserChange = (e) => {
    setSelectedUser(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    const dataToSubmit = {
      ...formData,
      transferAmount: parseFloat(formData.transferAmount), // Ensure transferAmount is sent as a number
      userId: selectedUser
    };

    try {
      const response = await fetch(`${API_BASE_URL}/admin/creditbank/${selectedUser}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(dataToSubmit)
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Bank transfer created successfully');
        console.log('Form data submitted:', result);
      } else {
        const error = await response.json();
        toast.error(error.message || 'Failed to create bank transfer');
        console.error('Error:', error.message);
      }
    } catch (error) {
      toast.error('An error occurred while submitting the form');
      console.error('Error submitting form data:', error);
    }
  };

  return (
    <React.Fragment>
      <Header/>
      <div className="form-container">
      <h2 className="form-title">Credit Bank</h2>
      <form onSubmit={handleSubmit} className="credit-bank-form">
        <div className="form-group">
          <label htmlFor="user">Select User</label>
          <select id="user" name="user" value={selectedUser} onChange={handleUserChange} required>
            <option value="">Select a user</option>
            {users.map(user => (
              <option key={user._id} value={user._id}>
                {user.username} {/* Assuming 'username' is the property that holds the user's display name */}
              </option>
            ))}
          </select>
        </div>
        <div className="form-group">
          <label htmlFor="sourceName">Source Name</label>
          <input
            type="text"
            id="sourceName"
            name="sourceName"
            value={formData.sourceName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="sourceBankAccountNumber">Source Account Number</label>
          <input
            type="text"
            id="sourceBankAccountNumber"
            name="sourceBankAccountNumber"
            value={formData.sourceBankAccountNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="sourceBankName">Source Bank Name</label>
          <input
            type="text"
            id="sourceBankName"
            name="sourceBankName"
            value={formData.sourceBankName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="transferAmount">Transfer Amount</label>
          <input
            type="number"
            id="transferAmount"
            name="transferAmount"
            value={formData.transferAmount}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="paymentDescription">Payment Description</label>
          <textarea
            id="paymentDescription"
            name="paymentDescription"
            value={formData.paymentDescription}
            onChange={handleChange}
            required
          ></textarea>
        </div>
        <div className="form-group">
          <label htmlFor="transferType">Transfer Type</label>
          <select
            id="transferType"
            name="transferType"
            value={formData.transferType}
            onChange={handleChange}
            required
          >
            <option value="Credit transfer">Credit transfer</option>
            <option value="Wire transfer">Wire transfer</option>
            <option value="Cheque">Cheque</option>
            <option value="ACH">ACH</option>
            <option value="one-time transfer">One-time transfer</option>
          </select>
        </div>
        <button type="submit" className="submit-button">Credit Bank</button>
      </form>
      <ToastContainer />
    </div>
    <Footer/>
    </React.Fragment>   
  );
};

export default CreditBank;
