import React, { useState } from "react";
import "./SuccessBanner.scss";

const SuccessBanner = () => {
  const [visible, setVisible] = useState(true);

  const handleClose = () => {
    setVisible(false);
  };

  if (!visible) return null;

  return (
    <div className="success-banner-overlay">
      <div className="success-banner">
        <div className="checkmark">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 52 52"
            className="checkmark-svg"
          >
            <circle className="checkmark-circle" cx="26" cy="26" r="25" />
            <path
              className="checkmark-check"
              fill="none"
              d="M14 27l7.8 7.8 16.2-16.2"
            />
          </svg>
        </div>
        <h1>Done!</h1>
        <button onClick={handleClose} className="close-button">
          Close
        </button>
      </div>
    </div>
  );
};

export default SuccessBanner;
