import React, { useState, useEffect } from "react";
import "./AllBeneficiaries.scss";
import Footer from "../../components/footer/Footer";
import Header from "../../components/header/AdminHeader";
import API_BASE_URL from "../../Config/config";

const AllBeneficiaries = () => {
  const [beneficiaries, setBeneficiaries] = useState([]);

  useEffect(() => {
    const fetchBeneficiaries = async () => {
      try {
        const response = await fetch(
          `${API_BASE_URL}/admin/beneficiaries`
        );
        const data = await response.json();
        setBeneficiaries(data.beneficiaries);
      } catch (error) {
        console.error("Error fetching beneficiaries:", error);
      }
    };

    fetchBeneficiaries();
  }, []);

  const toggleStatus = async (id) => {
    console.log("Toggling status for id:", id); // Debug the id
    const updatedBeneficiaries = beneficiaries.map((beneficiary) =>
      beneficiary._id === id // Use the correct key
        ? {
            ...beneficiary,
            approval:
              beneficiary.approval === "Approved"
                ? "Pending Approval"
                : "Approved",
          }
        : beneficiary
    );

    setBeneficiaries(updatedBeneficiaries);

    try {
      const response = await fetch(
        `${API_BASE_URL}/admin/beneficiaries/${id}/toggle-status`,
        {
          method: "POST",
        }
      );
      if (!response.ok) {
        throw new Error("Network response was not ok");
      }
    } catch (error) {
      console.error("Error toggling status:", error);
    }
  };

  return (
    <React.Fragment>
      <Header />
      <div className="beneficiary-table-container">
        <h1 className="title">Beneficiaries</h1>
        <table className="beneficiary-table">
          <thead>
            <tr>
              <th>Name</th>
              <th>Email</th>
              <th>Status</th>
              <th>Action</th>
            </tr>
          </thead>
          <tbody>
            {beneficiaries.map((beneficiary) => (
              <tr key={beneficiary._id}>
                <td data-label="Name">{beneficiary.fullName}</td>
                <td data-label="Email">{beneficiary.email}</td>
                <td data-label="Status">{beneficiary.approval}</td>
                <td data-label="Action">
                  <button
                    className="status-button"
                    onClick={() => toggleStatus(beneficiary._id)}
                  >
                    {beneficiary.approval === "Approved"
                      ? "Set Pending"
                      : "Approve"}
                  </button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default AllBeneficiaries;
