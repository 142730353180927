import React, { useState, useEffect } from 'react';
import './AllCustomerInfo.scss';
import 'react-toastify/dist/ReactToastify.css';
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const CustomerInfo = () => {
  const [customers, setCustomers] = useState([]);
  const [selectedCustomer, setSelectedCustomer] = useState(null);
  const [showOverlay, setShowOverlay] = useState(false);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchCustomers = async () => {
      try {
        const response = await fetch(`${API_BASE_URL}/admin/customer-bank-info`);
        const data = await response.json();

        console.log('Fetched data:', data); // Log the response data for debugging

        if (data && Array.isArray(data.customerBankInfo)) {
          setCustomers(data.customerBankInfo);
        } else {
          throw new Error('Data is not an array');
        }
      } catch (error) {
        console.error('Error fetching customer info:', error);
        setError(error.message);
      }
    };

    fetchCustomers();
  }, []);

  const deleteCustomer = async (bankAccountNumber) => {
    try {
      await fetch(`${API_BASE_URL}/admin/customer-bank-info/${bankAccountNumber}`, {
        method: 'DELETE',
      });
      setCustomers(customers.filter(customer => customer.bankAccountNumber !== bankAccountNumber));
      window.location.reload(); // Refresh the page after successful deletion
    } catch (error) {
      console.error('Error deleting customer info:', error);
      setError(error.message);
    }
  };

  const updateCustomer = (customer) => {
    setSelectedCustomer(customer);
    setShowOverlay(true);
  };

  const handleUpdateSubmit = async (event) => {
    event.preventDefault();
    const { accountName, bankName, pinCode, routingNumber, bankAddress, reference } = event.target.elements;

    try {
      const response = await fetch(`${API_BASE_URL}/admin/customer-bank-info/${selectedCustomer.bankAccountNumber}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          accountName: accountName.value,
          bankName: bankName.value,
          pinCode: pinCode.value,
          routingNumber: routingNumber.value,
          bankAddress: bankAddress.value,
          reference: reference.value,
        }),
      });

      if (response.ok) {
        const updatedCustomer = await response.json();
        setCustomers(customers.map(c => c.bankAccountNumber === updatedCustomer.bankAccountNumber ? updatedCustomer : c));
        setShowOverlay(false);
        window.location.reload(); // Refresh the page after successful update
      } else {
        console.error('Failed to update customer info');
        setError('Failed to update customer info');
      }
    } catch (error) {
      console.error('Error updating customer info:', error);
      setError(error.message);
    }
  };

  const closeOverlay = () => {
    setShowOverlay(false);
    setSelectedCustomer(null);
  };

  if (error) {
    return <div>Error: {error}</div>;
  }

  return (
    <React.Fragment>
      <Header />
      <div className="customer-info-table-container">
        <h1 className="title">Customer Information</h1>
        <table className="customer-info-table">
          <thead>
            <tr>
              <th>Account Name</th>
              <th>Bank Account Number</th>
              <th>Bank Name</th>
              <th>Routing Number/IBAN</th>
              <th>Bank Address</th>
              <th>Pin Code</th>
              <th>Actions</th>
            </tr>
          </thead>
          <tbody>
            {customers.map((customer) => (
              <tr key={customer.bankAccountNumber}>
                <td>{customer.accountName}</td>
                <td>{customer.bankAccountNumber}</td>
                <td>{customer.bankName}</td>
                <td>{customer.routingNumber}</td>
                <td>{customer.bankAddress}</td>
                <td>{customer.pinCode}</td>
                <td>
                  <button className="update-button" onClick={() => updateCustomer(customer)}>Update</button>
                  <button className="delete-button" onClick={() => deleteCustomer(customer.bankAccountNumber)}>Delete</button>
                </td>
              </tr>
            ))}
          </tbody>
        </table>

        {showOverlay && selectedCustomer && (
          <div className="overlay">
            <div className="overlay-content">
              <h2>Update Customer Information</h2>
              <form onSubmit={handleUpdateSubmit}>
                <label>
                  Account Name:
                  <input type="text" name="accountName" defaultValue={selectedCustomer.accountName} required />
                </label>
                <label>
                  Bank Name:
                  <input type="text" name="bankName" defaultValue={selectedCustomer.bankName} required />
                </label>
                <label>
                  Pin Code:
                  <input type="text" name="pinCode" defaultValue={selectedCustomer.pinCode} required />
                </label>
                <label>
                  Routing Number / IBAN:
                  <input type="text" name="routingNumber" maxLength={34} minLength={9} defaultValue={selectedCustomer.routingNumber} />
                </label>
                <label>
                  Bank Address:
                  <input type="text" name="bankAddress" defaultValue={selectedCustomer.bankAddress} />
                </label>
                <label>
                  Reference:
                  <input type="text" name="reference" defaultValue={selectedCustomer.reference} />
                </label>
                <button type="submit">Save</button>
                <button type="button" onClick={closeOverlay}>Cancel</button>
              </form>
            </div>
          </div>
        )}
      </div>
      <Footer />
    </React.Fragment>
  );
};

export default CustomerInfo;
