import React from "react";
import "./Card.scss";

const Card = ({ fullName, relationship, dateOfBirth, address, percentageAllocation, approval }) => {
  return (
    <div className="card-container">
      <div className="card-header">
        <h3>{fullName}</h3>
        <p>{relationship}</p>
      </div>
      <div className="card-body">
        <div className="card-row">
          <p>Date of Birth:</p>
          <p>{dateOfBirth}</p>
        </div>
        <div className="card-row">
          <p>Address:</p>
          <p>{address}</p>
        </div>
        <div className="card-row">
          <p>Percentage Allocation:</p>
          <p>{percentageAllocation}%</p>
        </div>
        <div className="card-row">
          <p>Approval:</p>
          <p>{approval}</p>
        </div>
      </div>
    </div>
  );
};

export default Card;
