import React from 'react';
import './Header.scss';
import logo from '../../assets/images/logo.png'; // Ensure the logo image path is correct
import { Link } from 'react-router-dom';
import { isAuthenticated } from '../../components/Auth/auth'; // Ensure this import is correct

const Header = () => {
  const loggedIn = isAuthenticated();

  return (
    <header className="header">
      <div className="logo-container">
        <img src={logo} alt="Logo" className="logo" />
      </div>
      <div className="header-text">
        <h2>Riveforte Bank</h2>
      </div>
      <div className="buttons-container">
        {loggedIn ? (
          <Link to="/dashboard">
            <button className="header-button">Dashboard</button>
          </Link>
        ) : (
          <Link to="/login">
            <button className="header-button">Login</button>
          </Link>
        )}
      </div>
    </header>
  );
};

export default Header;
