import React from 'react';
import './CautionMessage.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faExclamationTriangle } from '@fortawesome/free-solid-svg-icons';

const CautionMessage = () => {
  return (
    <div className="caution-message">
      <FontAwesomeIcon icon={faExclamationTriangle} size="3x" className="caution-icon" />
      <div className="message-content">
        <h3 className="message-title">Important Notice</h3>
        <p className="message-text">
          Please keep your login information private to prevent fraud. Never share your username and password with anyone.
        </p>
      </div>
    </div>
  );
};

export default CautionMessage;
