import {jwtDecode} from 'jwt-decode';
import API_BASE_URL from '../../Config/config';

export const isAuthenticated = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return false;
  }

  try {
    // Decode the token to get the payload
    const decodedToken = jwtDecode(token);
    // Check if the token is expired
    const currentTime = Date.now() / 1000; // Convert to seconds
    if (decodedToken.exp < currentTime) {
      // Token is expired
      localStorage.removeItem('token'); // Optional: Remove expired token
      return false;
    }

    return true;
  } catch (error) {
    console.error('Error decoding token:', error);
    return false;
  }
};

export const getUser = () => {
  const token = localStorage.getItem('token');
  if (!token) {
    return null;
  }

  try {
    // Decode the token to get user info
    const decodedToken = jwtDecode(token);
    return decodedToken.user;
  } catch (error) {
    console.error('Error decoding token:', error);
    return null;
  }
};

export const getUserData = async () => {
    const token = localStorage.getItem('token');
    if (!token) {
      return null;
    }
    const decoded = jwtDecode(token);
    // Fetch user data from backend if necessary
    const response = await fetch(`${API_BASE_URL}/user/${decoded.user.id}`);
    const data = await response.json();
    return data;
  };

export const login = (token) => {
  localStorage.setItem('token', token);
};

export const logout = () => {
  localStorage.removeItem('token');
};
