import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import "./CreateUser.scss";
import API_BASE_URL from "../../Config/config";

const CreateUser = ({ onClose, onCreate }) => {
  const [formData, setFormData] = useState({
    fullname: "",
    email: "",
    username: "",
    password: "",
    accountNumber: "",
    accountBalance: "",
    accountType: "",
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/admin/createuser`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(formData),
      });

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Failed to create user");
      }

      const newUser = await response.json();
      onCreate?.(newUser); // Use optional chaining to call onCreate if it is defined
      toast.success("User created successfully!");
      onClose?.(); // Use optional chaining to call onClose if it is defined
    } catch (error) {
      toast.error(`Error creating user: ${error.message}`);
    }
  };

  return (
    <div className="overlay">
      <div className="overlay-content">
        <h2>Create New User</h2>
        <form onSubmit={handleSubmit}>
          <label>
            Full Name:
            <input
              type="text"
              name="fullname"
              value={formData.fullname}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Email:
            <input
              type="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Username:
            <input
              type="text"
              name="username"
              value={formData.username}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Password:
            <input
              type="password"
              name="password"
              value={formData.password}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Number:
            <input
              type="text"
              name="accountNumber"
              value={formData.accountNumber}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Balance:
            <input
              type="number"
              name="accountBalance"
              value={formData.accountBalance}
              onChange={handleChange}
              required
            />
          </label>
          <label>
            Account Type:
            <select
              name="accountType"
              value={formData.accountType}
              onChange={handleChange}
              required
            >
              <option value="">Select an account type</option>
              <option value="savings account">Savings Account</option>
              <option value="checking account">Fixed Deposit Account</option>
              <option value="checking account">Checking Account</option>
              <option value="money market">Money Market</option>
              <option value="certificate of deposit">Certificate of Deposit</option>
              <option value="individual retirement account">Individual Retirement Account</option>
              <option value="joint account">Joint Account</option>
            </select>
          </label>
          <button type="submit">Create</button>
          <button type="button" onClick={onClose}>
            Cancel
          </button>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
};

export default CreateUser;
