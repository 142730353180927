import React, { useState } from "react";
import { Sidebar, Menu, MenuItem } from "react-pro-sidebar";
import { Link } from "react-router-dom";
// import 'react-pro-sidebar/dist/css/styles.css'; // Ensure you import the default styles
import "./Sidebar.scss";

const CustomSidebar = () => {
  const [toggled, setToggled] = useState(false);

  return (
    <div style={{ display: "flex", height: "100%" }}>
      <Sidebar
        onBackdropClick={() => setToggled(false)}
        toggled={toggled}
        breakPoint="always"
        style={{ height: "100%" }}
      >
        <Menu>
          <MenuItem component={<Link to="/" />}>Home</MenuItem>
          <MenuItem component={<Link to="/dashboard" />}>Dashboard</MenuItem>
          <MenuItem component={<Link to="/transfer" />}>
            Payment/Transfer
          </MenuItem>
          <MenuItem component={<Link to="/beneficiary" />}>
            View Beneficiaries
          </MenuItem>
          <MenuItem component={<Link to="/new-beneficiary" />}>
            Add Beneficiaries
          </MenuItem>
        </Menu>
      </Sidebar>
      <main style={{ display: "flex", padding: 10 }}>
        <div>
          <button className="sb-button" onClick={() => setToggled(!toggled)}>
            MENU
          </button>
        </div>
      </main>
    </div>
  );
};

export default CustomSidebar;
