import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { login } from '../../components/Auth/auth';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './AdminLogin.scss';
import API_BASE_URL from '../../Config/config';

const AdminLogin = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/admin/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const { token } = await response.json();
        login(token);
        toast.success('Login successful');
        navigate('/admin');
      } else {
        const error = await response.json();
        toast.error(`Login failed: ${error.message}`);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Login failed: Server error');
    }
  };

  return (
    <div className="admin-login-container">
      <h1>Admin Login</h1>
      <form onSubmit={handleSubmit} className="admin-login-form">
        <div className="form-group">
          <label htmlFor="username">Username</label>
          <input
            type="text"
            id="username"
            name="username"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="password">Password</label>
          <input
            type="password"
            id="password"
            name="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
          />
        </div>
        <button type="submit" className="login-button">Login</button>
      </form>
    </div>
  );
};

export default AdminLogin;
