import React, { useState } from 'react';
import './Login.scss';
import CautionMessage from '../../components/caution/Caution';
import logo from '../../assets/images/logo.png'; // Ensure the logo image path is correct
import HeaderNoButton from '../../components/header/HeaderNoButton'
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import { login } from '../../components/Auth/auth';
import API_BASE_URL from '../../Config/config';

const LoginPage = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch(`${API_BASE_URL}/user/login`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      });

      if (response.ok) {
        const { token } = await response.json();
        login(token);
        toast.success('Login successful');
        setTimeout(() => {
          navigate('/dashboard');
        }, 2000); // Delay navigation for 2 seconds
      } else {
        const error = await response.json();
        toast.error(`Login failed: ${error.message}`);
      }
    } catch (error) {
      console.error('Error logging in:', error);
      toast.error('Login failed: Server error');
    }
  };

  return (
    <React.Fragment>
      <HeaderNoButton/>
      <CautionMessage/>
      <div className="login-page">
        <div className="overlay"></div>
        <div className="login-container">
          <div className="logo-container">
            <img src={logo} alt="Logo" className="logo" />
          </div>
          <h2 className="login-title">Welcome Back</h2>
          <form onSubmit={handleSubmit} className="login-form">
            <div className="input-group">
              <label htmlFor="username">ID</label>
              <input 
                type="text" 
                id="username" 
                name="username" 
                placeholder='Input your user ID'  
                value={username}
                onChange={(e) => setUsername(e.target.value)}
                required 
              />
            </div>
            <div className="input-group">
              <label htmlFor="password">Password</label>
              <input 
                type="password" 
                id="password" 
                name="password" 
                value={password}
                onChange={(e) => setPassword(e.target.value)}
                required 
              />
            </div>
            <button type="submit" className="login-button">Login</button>
            <p className="signup-link">
              {/* Don't have an account? <a href="/signup">Sign up</a> */}
            </p>
          </form>
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  );
};

export default LoginPage;
