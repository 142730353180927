import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Transfer.scss";
import bankData from "./data/banks.json"; // Import the JSON file
import HeaderNoButton from "../../components/header/HeaderNoButton";
import Footer from "../../components/footer/Footer";
import Logout from "../../components/Button/Logout";
import CustomSidebar from "../../components/sidebar/Sidebar";
import axios from "axios";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import API_BASE_URL from "../../Config/config";
import { jwtDecode } from "jwt-decode";
import WarningOverlay from "../../components/WarningOverlay/WarningOverlay";
import SuccessBanner from "../../components/SuccessBanner/SuccessBanner"; // Import the SuccessBanner component

const BankTransferForm = () => {
  const [formData, setFormData] = useState({
    bankName: "",
    bankAccountNumber: "",
    recipientName: "Please wait...",
    iban: "",
    transferAmount: "",
    paymentDescription: "",
    transferType: "one-time transfer",
    pin: "",
  });
  const [isFetching, setIsFetching] = useState(false);
  const [accountError, setAccountError] = useState("");
  const [showSuccessBanner, setShowSuccessBanner] = useState(false); // New state for showing success banner
  const [hasToastDisplayed, setHasToastDisplayed] = useState(false); // New state for tracking toast display
  const navigate = useNavigate();

  const [userData, setUserData] = useState(null);

  useEffect(() => {
    const fetchUserData = async () => {
      try {
        const token = localStorage.getItem("token");
        const decodedToken = jwtDecode(token);
        const userId = decodedToken.user.id;

        const userResponse = await axios.get(
          `${API_BASE_URL}/user/user/${userId}`,
          {
            headers: { Authorization: `Bearer ${token}` },
          }
        );
        setUserData(userResponse.data.user);
      } catch (error) {
        console.error("Error fetching user data:", error);
      }
    };

    fetchUserData();
  }, []);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
    if (name === "bankAccountNumber") {
      setAccountError("");
    }
  };

  const fetchRecipientName = async (bankName, bankAccountNumber) => {
    setIsFetching(true);
    setFormData((prevFormData) => ({
      ...prevFormData,
      recipientName: "Please wait...",
    }));

    try {
      const response = await axios.get(
        `${API_BASE_URL}/user/get-account-name`,
        {
          params: { bankName, bankAccountNumber },
        }
      );
      if (response.data) {
        setFormData((prevFormData) => ({
          ...prevFormData,
          recipientName: response.data.accountName,
        }));
        setAccountError("");
        setHasToastDisplayed(false); // Reset toast display flag on success
      } else {
        setFormData((prevFormData) => ({
          ...prevFormData,
          recipientName: "",
        }));
        setAccountError("Account unavailable, ensure information is correct");
      }
    } catch (error) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        recipientName: "",
      }));
      setAccountError("Account unavailable, ensure information is correct");
      if (!hasToastDisplayed) {
        toast.error("Failed to fetch recipient name");
        setHasToastDisplayed(true); // Set flag to true after showing toast
      }
    } finally {
      setIsFetching(false);
    }
  };

  const handleBlur = () => {
    if (formData.bankName && formData.bankAccountNumber) {
      fetchRecipientName(formData.bankName, formData.bankAccountNumber);
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const token = localStorage.getItem("token"); // assuming the token is stored in localStorage
      await axios.post(`${API_BASE_URL}/user/bank-transfer`, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      });

      setShowSuccessBanner(true); // Show success banner on successful submission
      setTimeout(() => {
        setShowSuccessBanner(false); // Hide banner
        navigate("/dashboard"); // Navigate to dashboard
      }, 2000); // Delay navigation for 2 seconds
    } catch (error) {
      toast.error(
        error.response?.data?.message || "Failed to create bank transfer"
      );
      console.error("There was an error!", error);
    }
  };

  return (
    <React.Fragment>
      <WarningOverlay isSuspended={userData?.suspended} />
      <HeaderNoButton />
      <CustomSidebar />
      <div className="bank-transfer-form">
        <h2>Bank Transfer</h2>
        <form onSubmit={handleSubmit}>
          <div className="input-group">
            <label htmlFor="bankName">Bank Name</label>
            <select
              id="bankName"
              name="bankName"
              value={formData.bankName}
              onChange={handleChange}
              required
            >
              <option value="" disabled>
                Select a bank
              </option>
              {bankData.map((bank) => (
                <option key={bank.bankName} value={bank.bankName}>
                  {bank}
                </option>
              ))}
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="bankAccountNumber">Account Number</label>
            <input
              type="text"
              id="bankAccountNumber"
              name="bankAccountNumber"
              value={formData.bankAccountNumber}
              onChange={handleChange}
              onBlur={handleBlur}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="recipientName">Recipient Name</label>
            <input
              type="text"
              id="recipientName"
              name="recipientName"
              value={formData.recipientName}
              readOnly
              required
            />
            {accountError && <p style={{ color: "red" }}>{accountError}</p>}
          </div>

          <div className="input-group">
            <label htmlFor="iban">Routing Number/IBAN</label>
            <input
              type="text"
              id="iban"
              name="iban"
              value={formData.iban}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="transferAmount">Amount ($)</label>
            <input
              type="number"
              id="transferAmount"
              name="transferAmount"
              value={formData.transferAmount}
              onChange={handleChange}
              required
            />
          </div>

          <div className="input-group">
            <label htmlFor="paymentDescription">Payment Description</label>
            <input
              type="text"
              id="paymentDescription"
              name="paymentDescription"
              value={formData.paymentDescription}
              onChange={handleChange}
            />
          </div>

          <div className="input-group">
            <label htmlFor="transferType">Type of Transfer</label>
            <select
              id="transferType"
              name="transferType"
              value={formData.transferType}
              onChange={handleChange}
              required
            >
              <option value="one-time transfer">One-time Transfer</option>
              <option value="recurring transfer">Recurring Transfer</option>
              <option value="scheduled payment">Scheduled Payment</option>
            </select>
          </div>

          <div className="input-group">
            <label htmlFor="pin">PIN</label>
            <input
              type="password"
              id="pin"
              name="pin"
              value={formData.pin}
              onChange={handleChange}
              required
            />
          </div>

          <button type="submit" className="submit-button">
            Submit
          </button>
        </form>
      </div>
      <Footer />
      <Logout />
      <ToastContainer />
      {showSuccessBanner && <SuccessBanner />} {/* Render the SuccessBanner conditionally */}
    </React.Fragment>
  );
};

export default BankTransferForm;
