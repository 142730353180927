import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import './Testimonial.scss';

const testimonials = [
  {
    name: 'Dr. John McAfee Anderson',
    text: 'I have been a customer of Riveforte  Bank for over five years, and I cannot speak highly enough of their services. The staff always go above and beyond to ensure my needs are met, and the online banking platform is incredibly user-friendly. Their commitment to security gives me peace of mind, knowing my financial information is well-protected. Riveforte  Bank has truly empowered me to manage my finances effectively and achieve my financial goals.',
    image: 'https://img.icons8.com/3d-fluency/94/administrator-male--v3.png',
  },
  {
    name: 'Mrs. Emily Eskra',
    text: 'Switching to Riveforte  Bank was one of the best financial decisions I have ever made. The personalized attention and exceptional customer service make me feel valued as a customer. Their range of products and services perfectly meets my needs, from savings accounts to mortgage options. The community involvement initiatives are also commendable, and it iss great to see a bank that genuinely cares about giving back. I highly recommend Riveforte  Bank to anyone looking for a reliable and customer-focused bank.',
    image: 'https://img.icons8.com/3d-fluency/94/businesswoman--v3.png',
  },
  {
    name: 'Natalie Sarah Martinez',
    text: 'Riveforte  Bank has been a fantastic partner in my financial journey. Their digital banking services are top-notch, providing me with easy access to my accounts and seamless transaction capabilities. The financial advisors are knowledgeable and always available to offer guidance and support. I appreciate their efforts to educate customers on financial literacy, which has helped me make informed decisions about my investments. I am grateful for the excellent service and support I have received from Riveforte  Bank.',
    image: 'https://img.icons8.com/3d-fluency/94/person-female.png',
  },
];

const TestimonialSlider = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 3000,
  };

  return (
    <React.Fragment>
       <div className='testimonial-heading'>
        <h1>WHY OUR CLIENTS LOVE US</h1>
        <p>
        We pride ourselves on building strong, lasting 
        relationships with our clients. Read below to hear 
        some of their stories.
        </p>
    </div> 

    <div className="testimonial-slider">
        <Slider {...settings}>
            {testimonials.map((testimonial, index) => (
            <div key={index} className="testimonial">
                <img src={testimonial.image} alt={testimonial.name} className="testimonial-image" />
                <p className="testimonial-text">"{testimonial.text}"</p>
                <h4 className="testimonial-name">{testimonial.name}</h4>
            </div>
            ))}
        </Slider>
        </div>
    </React.Fragment>
    
    
  );
};

export default TestimonialSlider;
