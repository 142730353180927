import React from 'react';
import './WarningOverlay.scss'; // Import the SCSS file

const WarningOverlay = ({ isSuspended }) => {
  return (
    isSuspended && (
      <div className="warning-overlay">
        <div className="warning-message">
          <h2>Account Suspended</h2>
          <p>Your account has been suspended, and you will be unable 
            to perform transactions at this time.</p> 
            <p>Please visit one of 
            our nearby branches to resolve this matter.</p>
        </div>
      </div>
    )
  );
};

export default WarningOverlay;