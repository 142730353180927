import { BrowserRouter, Routes, Route } from "react-router-dom";
import './App.css';
import UserDashboard from './pages/UserDashboard/UserDashboard';
import Transfer from './pages/Transfer/Transfer';
import AddBeneficiary from "./pages/Beneficiary/AddBeneficiary";
import Beneficiary from "./pages/Beneficiary/Beneficiary";
import Home from "./pages/Home/Home";
import LoginPage from "./pages/Login/Login";
import AdminDashboard from "./pages/AdminDashboard/AdminDashboard";
import AllUsers from "./pages/AdminPages/AllUsers";
import CreateUser from "./components/CreateUser/CreateUser";
import AllBeneficiaries from "./pages/AdminPages/AllBeneficiaries";
import AllCustomerInfo from "./pages/AdminPages/AllCustomerInfo";
import AddCustomerInfo from "./pages/AdminPages/AddCustomerInfo";
import CreditUserAccount from "./pages/AdminPages/CreditUserAccount";
import AdminLogin from "./pages/Login/AdminLogin";
import ProtectedRoute from "./components/ProtectedRoute/ProtectedRoute";
import UserProtectedRoute from "./components/ProtectedRoute/UserProtectedRoute";
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Transactions from "./pages/AdminPages/Transactions";
import PasswordResetForm from "./pages/AdminPages/PasswordResetForm";
import FixedDepositForm from "./pages/AdminPages/FixedDespositForm";

function App() {
  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Home/>} />
        <Route path="/login" element={<LoginPage/>}/>
        <Route path="/dashboard" 
          element={
            <UserProtectedRoute>
              <UserDashboard />
            </UserProtectedRoute>
          } 
        />
        <Route path="/transfer" 
          element={
            <UserProtectedRoute>
              <Transfer/>
            </UserProtectedRoute>
          } 
        />
        <Route path="/new-beneficiary" 
          element={
            <UserProtectedRoute>
              <AddBeneficiary/>
            </UserProtectedRoute>
          } 
        />
        <Route path="/beneficiary" 
          element={
            <UserProtectedRoute>
              <Beneficiary/>
            </UserProtectedRoute>
          } 
        />
        <Route path="/admin/login" element={<AdminLogin />} />
        <Route 
          path="/admin" 
          element={
            <ProtectedRoute>
              <AdminDashboard />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/allusers" 
          element={
            <ProtectedRoute>
              <AllUsers />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/create-user" 
          element={
            <ProtectedRoute>
              <CreateUser />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/all-beneficiaries" 
          element={
            <ProtectedRoute>
              <AllBeneficiaries />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/all-customerinfo" 
          element={
            <ProtectedRoute>
              <AllCustomerInfo />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/add-customerInfo" 
          element={
            <ProtectedRoute>
              <AddCustomerInfo />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/transactions" 
          element={
            <ProtectedRoute>
              <Transactions />
            </ProtectedRoute>
          } 
        />
        <Route 
          path="/admin/credit-account" 
          element={
            <ProtectedRoute>
              <CreditUserAccount />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/admin/reset-password" 
          element={
            <ProtectedRoute>
              <PasswordResetForm />
            </ProtectedRoute>
          }
        />
        <Route 
          path="/admin/fixed-deposits" 
          element={
            <ProtectedRoute>
              <FixedDepositForm />
            </ProtectedRoute>
          }
        />
      </Routes>
      <ToastContainer />     
    </BrowserRouter>
  );
}

export default App;
