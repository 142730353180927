import React from 'react';
import './InfoCards.scss';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faShieldAlt, faMobileAlt, faUserTie } from '@fortawesome/free-solid-svg-icons';


const InfoCards = () => {
  const cardData = [
    {
      icon: faShieldAlt, // FontAwesome class for the icon
      title: 'Secure Banking',
      caption: 'Your peace of mind is our priority. Enjoy secure and reliable banking services with us.'
    },
    {
      icon: faMobileAlt,
      title: 'Convenient Online Services',
      caption: 'Access your accounts and manage your finances with our convenient online and mobile banking services.'
    },
    {
      icon: faUserTie,
      title: 'Personalized Financial Solutions',
      caption: 'We offer personalized banking and financial services to meet your unique needs and goals.'
    }
  ];

  return (
    <div className="info-cards-container">
      {cardData.map((card, index) => (
        <div className="card" key={index}>
          <div className="card-icon">
          <FontAwesomeIcon icon={card.icon} size="3x" />
          </div>
          <div className="card-content">
            <h3 className="card-title">{card.title}</h3>
            <p className="card-caption">{card.caption}</p>
          </div>
        </div>
      ))}
    </div>
  );
};

export default InfoCards;
