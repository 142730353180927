import React from 'react';
import './Footer.scss';
import logo from '../../assets/images/logo.png'; // Ensure the logo image path is correct

const Footer = () => {
  return (
    <footer className="footer">
      <div className="footer-about">
        <img src={logo} alt="Bank Logo" className="footer-logo" />
        <p>
          At <strong>Riveforte Bank</strong>, we are committed to providing exceptional financial services with integrity and a customer-first approach. Our motto is "Empowering Your Financial Future," and we strive to uphold our values of trust, innovation, and community involvement in everything we do.
        </p>
      </div>
      <div className="footer-contact">
        <h3>Contact Us</h3>
        <p>Email: support@riveforte.com</p>
        <p>Telephone: (413) 258-0472</p>
        <p>Telephone: (254) 545-2994</p>
        <p>Address: 6310 Broadway Blvd, Garland, Texas, 75043</p>
      </div>
    </footer>
  );
};

export default Footer;
