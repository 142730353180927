import React from 'react';
import './TransactionPagination.scss';

const Pagination = ({ currentPage, totalPages, onPageChange }) => {
  const handlePageChange = (page) => {
    onPageChange(page);
  };

  const paginationItems = [];
  for (let i = 1; i <= totalPages; i++) {
    paginationItems.push(
      <li key={i}>
        <a
          href="#!"
          className={currentPage === i ? 'active' : ''}
          onClick={() => handlePageChange(i)}
        >
          {i}
        </a>
      </li>
    );
  }

  return (
    <ul className="pagination">
      {currentPage > 1 && (
        <li>
          <a href="#!" onClick={() => handlePageChange(currentPage - 1)}>
            Previous
          </a>
        </li>
      )}
      {paginationItems}
      {currentPage < totalPages && (
        <li>
          <a href="#!" onClick={() => handlePageChange(currentPage + 1)}>
            Next
          </a>
        </li>
      )}
    </ul>
  );
};

export default Pagination;
