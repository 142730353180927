import React, { useState } from 'react';
import './AddCustomerBankInfo.scss';
import { toast, ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { useNavigate } from 'react-router-dom';
import API_BASE_URL from '../../Config/config';
import Header from '../../components/header/AdminHeader';
import Footer from '../../components/footer/Footer';

const AddCustomerBankInfo = () => {
  const [formData, setFormData] = useState({
    accountName: '',
    bankAccountNumber: '',
    routingNumber: '',
    bankName: '',
    bankAddress: '',
    reference: '',
    pinCode: ''
  });

  const navigate = useNavigate();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch(`${API_BASE_URL}/admin/customer-info`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        body: JSON.stringify(formData)
      });

      if (response.ok) {
        const result = await response.json();
        toast.success('Customer bank information added successfully');
        setFormData({
          accountName: '',
          bankAccountNumber: '',
          routingNumber: '',
          bankName: '',
          bankAddress: '',
          reference: '',
          pinCode: ''
        });
        setTimeout(() => {
          navigate('/admin/all-customerinfo');
        }, 3000); // 3 seconds timeout before navigation
      } else {
        const error = await response.json();
        toast.error(error.message);
      }
    } catch (error) {
      console.error('Error adding customer bank info:', error);
      toast.error('Server Error');
    }
  };

  return (
    <React.Fragment>
      <Header/>
      <div className="form-container">
      <h2 className="form-title">Add Customer Bank Information</h2>
      <form onSubmit={handleSubmit} className="customer-bank-info-form">
        <div className="form-group">
          <label htmlFor="accountName">Account Name</label>
          <input
            type="text"
            id="accountName"
            name="accountName"
            value={formData.accountName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="bankAccountNumber">Bank Account Number</label>
          <input
            type="text"
            id="bankAccountNumber"
            name="bankAccountNumber"
            value={formData.bankAccountNumber}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="routingNumber">Routing Number / IBAN</label>
          <input
            type="text"
            id="routingNumber"
            name="routingNumber"
            value={formData.routingNumber}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="bankName">Bank Name</label>
          <input
            type="text"
            id="bankName"
            name="bankName"
            value={formData.bankName}
            onChange={handleChange}
            required
          />
        </div>
        <div className="form-group">
          <label htmlFor="bankAddress">Bank Address</label>
          <input
            type="text"
            id="bankAddress"
            name="bankAddress"
            value={formData.bankAddress}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="reference">Reference</label>
          <input
            type="text"
            id="reference"
            name="reference"
            value={formData.reference}
            onChange={handleChange}
          />
        </div>
        <div className="form-group">
          <label htmlFor="pinCode">Pin Code</label>
          <input
            type="password"
            id="pinCode"
            name="pinCode"
            value={formData.pinCode}
            onChange={handleChange}
            required
          />
        </div>
        <button type="submit" className="submit-button">Add Customer</button>
      </form>
      <ToastContainer />
    </div>
    <Footer/>
    </React.Fragment>
    
  );
};

export default AddCustomerBankInfo;
